<template>
  <account-summary-skeleton v-if="loading" :top-accs-page="topAccsPage" />
  <error v-else-if="error" />
  <div
    v-for="(account, idx) in accounts"
    v-else
    :key="idx"
    class="list-group-item"
  >
    <div class="row justify-content-center">
      <div
        :class="topAccsPage ? 'col-xxl-4' : 'col-xxl-6'"
        class="d-flex mb-2 mb-md-0 overflow-hidden"
      >
        <router-link
          v-tooltip
          :to="
            !account.label
              ? {
                  name: 'DirectoryUpdateRoute',
                  query: {
                    account: account.account,
                  },
                }
              : {
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: account.account,
                  },
                }
          "
          class="flex-shrink-0 rounded-3 d-flex align-self-center text-reset"
          :class="labelData(account).background"
          style="height: 36px; width: 36px"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="labelData(account).tooltip"
        >
          <i
            class="bi fs-5 align-self-center mx-auto"
            :class="labelData(account).icon"
          ></i>
        </router-link>
        <div class="flex-shrink-0 ms-3">
          <router-link
            v-tooltip
            :to="{
              name: 'AccountDetailsRoute',
              params: {
                acc_id: account.account,
              },
            }"
            class="fw-bold text-decoration-none font-monospace small text-reset"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-original-title="$t('account_summary.label')"
          >
            {{ labelData(account).label }}
          </router-link>
          <p class="small mb-0 font-monospace">
            <router-link
              v-if="topAccsPage"
              v-tooltip
              v-middle-ellipsis="{
                size: 6,
                text: account.account,
              }"
              :to="{
                name: 'AccountDetailsRoute',
                params: {
                  acc_id: account.account,
                },
              }"
              class="text-muted text-decoration-none d-none d-sm-none d-md-flex"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_summary.account_id')"
            >
              {{ account.account }}
            </router-link>
            <router-link
              v-else
              v-tooltip
              :to="{
                name: 'AccountDetailsRoute',
                params: {
                  acc_id: account.account,
                },
              }"
              class="text-muted text-decoration-none d-none d-sm-none d-md-flex"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_summary.account_id')"
            >
              {{ account.account }}
            </router-link>
            <router-link
              v-tooltip
              v-middle-ellipsis="{
                size: 6,
                text: account.account,
              }"
              :to="{
                name: 'AccountDetailsRoute',
                params: {
                  acc_id: account.account,
                },
              }"
              class="text-muted text-decoration-none d-md-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_summary.account_id')"
            >
              {{ account.account }}
            </router-link>
          </p>
        </div>
      </div>
      <div class="col-sm d-block">
        <span class="d-block small text-muted"
          ><span>{{ $t("account_summary.balance") }}</span></span
        >
        <small
          v-tooltip
          class="fw-bold font-monospace"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
          :data-bs-original-title="'$ ' + (account.price_usd ?? '-')"
          >{{
            account?.balance ? account.balance.toLocaleString() : "-"
          }}
          XLM</small
        >
      </div>
      <div class="col-sm d-block">
        <span class="d-block small text-muted"
          ><span>{{ $t("account_summary.percent_of_coins") }}</span></span
        >
        <small class="fw-bold font-monospace"
          >{{ account.percent_of_coins ?? "- " }}%</small
        >
      </div>
      <div class="col-sm d-block">
        <span class="d-block small text-muted"
          ><span>{{ $t("account_summary.transactions") }}</span></span
        >
        <small class="fw-bold font-monospace">{{
          account?.transactions
            ? parseInt(account.transactions).toLocaleString()
            : "-"
        }}</small>
      </div>
      <div v-if="topAccsPage" class="col-md-2 col-8 mt-2 mt-lg-0">
        <span
          class="
            flex-shrink-0
            bg-primary bg-opacity-25
            rounded-3
            d-flex
            align-self-center
            w-100
            text-primary
            d-flex
          "
          style="height: 36px"
        >
          <span class="align-self-center mx-auto fw-bold"
            ># {{ account?.rank }}</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { capitalize } from "@/utils/string.js";
import AccountSummarySkeleton from "@/components/skeletons/summaries/AccountSummarySkeleton.vue";
import Error from "@/components/Error.vue";
export default defineComponent({
  name: "AccountSummary",
  components: { AccountSummarySkeleton, Error },
  props: {
    topAccsPage: Boolean,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();

    const labelData = (account) => {
      if (!account.label) {
        return {
          label: t("account_summary.unknown"),
          icon: "bi-plus-circle text-success",
          background: "",
          tooltip: t("account_summary.unknown_account"),
        };
      }

      if (account.label.verified && account.label.name) {
        switch (account.label.name) {
          case "Scam":
            return {
              label: t("account_summary.unknown"),
              icon: "bi-exclamation-triangle-fill text-danger",
              background: "bg-danger bg-opacity-25",
              tooltip: t("account_summary.scam_account"),
            };
          case "Hack":
            return {
              label: t("account_summary.unknown"),
              icon: "bi-exclamation-triangle-fill text-danger",
              background: "bg-danger bg-opacity-25",
              tooltip: t("account_summary.hack_account"),
            };
          default:
            return {
              label: account.label.name,
              icon: "bi-patch-check-fill text-primary",
              background: "bg-primary bg-opacity-25",
              tooltip: t("account_summary.verified_account"),
            };
        }
      } else if (!account.label.verified && account.label.name) {
        return {
          label: account.label.name,
          icon: "bi-person-circle",
          background: "",
          tooltip: t("account_summary.unverified_user_defined_label"),
        };
      }
    };
    const loading = computed(() =>
      props.topAccsPage
        ? store.getters["accounts/getTopAccounts"].loading
        : store.getters["accounts/getDirectoryAccounts"].loading
    );
    const error = computed(() =>
      props.topAccsPage
        ? store.getters["accounts/getTopAccounts"].error
        : store.getters["accounts/getDirectoryAccounts"].error
    );
    const accounts = computed(() =>
      props.topAccsPage
        ? store.getters["accounts/getTopAccounts"].data
        : store.getters["accounts/getDirectoryAccounts"].data
    );

    return {
      capitalize,
      accounts,
      labelData,
      loading,
      error,
    };
  },
});
</script>
