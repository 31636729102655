<template>
  <div class="d-block d-md-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>

    <div class="ms-2 mt-2 mt-md-0">
      <div
        style="min-width: 248px"
        class="d-flex justify-content-end justify-content-md-between"
      >
        <span class="d-inline-block">
          <router-link
            :to="{
              name: 'DirectoryUpdateRoute',
            }"
            class="btn text-primary border"
          >
            {{ $t("directory.label_my_account") }}
          </router-link></span
        >
        <span
          class="d-inline-block ms-1 ms-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('directory.previous_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="prevPage()"
            :disabled="disablePrevBtn"
          >
            <i class="bi bi-arrow-left-circle"></i></button
        ></span>
        <span
          class="d-inline-block ms-1 ms-md-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('directory.next_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="nextPage()"
            :disabled="disableNextBtn"
          >
            <i class="bi bi-arrow-right-circle"></i></button
        ></span>
      </div>
    </div>
  </div>
  <div v-if="label">
    <assets-summary />
  </div>
    <div class="list-group">
      <account-summary :topAccsPage="false" />
    </div>
  <div class="py-2 px-3 border rounded d-flex font-monospace my-2">
    <div class="flex-grow-1 small align-self-center text-muted">
      {{ $t("top_accounts.showing") }}<b>{{ directoryAccounts?.from }}</b
      >{{ $t("top_accounts.to") }}<b>{{ directoryAccounts?.to }}</b
      >{{ $t("top_accounts.of") }}<b>{{ directoryAccounts?.total }}</b>
    </div>
    <div class="flex-grow-0">
      <select
        class="form-select form-select-sm text-muted"
        aria-label=".form-select-sm example"
        v-model="paginate"
        @change="onChangePagination()"
      >
        <option v-for="(option, idx) in options" :value="option" :key="idx">
          {{ option + $t("top_accounts.rows") }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { capitalize } from "@/utils/string.js";
import Search from "@/components/Search.vue";
import AccountSummary from "@/components/summaries/AccountSummary.vue";
import AssetsSummary from "@/components/summaries/AssetsSummary.vue";

export default defineComponent({
  name: "Directory",
  components: { Search, AssetsSummary, AccountSummary },
  setup() {
    const store = useStore();
    const route = useRoute();
    const paginate = ref(25);
    const options = ref([25, 50, 100]);
    let label = route.query.label;
    watchEffect(() => {
        label = route.query.label;
        // Perform actions when the route query label changes
        store.dispatch("accounts/fetchDirectoryAccounts", {
          page: 1,
          paginate: paginate.value,
          labels: [route.query.label],
        });
        
        store.dispatch("assets/fetchAssets", {
          page: 1,
          sort: 'rating',
          code: route.query.label,
          paginate: paginate.value,
        });

        console.log('Route query label changed:', label);
    });
    const directoryAccounts = computed(
      () => store.getters["accounts/getDirectoryAccounts"]
    );
    const directoryAssets = computed(
      () => store.getters["assets/getAssets"]
    );
    const onChangePagination = () => {
      store.dispatch("accounts/fetchDirectoryAccounts", {
        page: 1,
        paginate: paginate.value,
        labels: [route.query.label],
      });
    };
    const nextPage = () => {
      store.dispatch("accounts/fetchDirectoryAccounts", {
        page: directoryAccounts.value.page + 1,
        paginate: paginate.value,
        labels: [route.query.label],
      });
    };
    const prevPage = () => {
      store.dispatch("accounts/fetchDirectoryAccounts", {
        page: directoryAccounts.value.page - 1,
        paginate: paginate.value,
        labels: [route.query.label],
      });
    };
    const disablePrevBtn = computed(() => directoryAccounts.value.page === 1);
    const disableNextBtn = computed(
      () => directoryAccounts.value.page === directoryAccounts.value.lastPage
    );

    return {
      capitalize,
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
      paginate,
      options,
      directoryAccounts,
      onChangePagination,
      directoryAssets,
      label
    };
  },
});
</script>

<style>
</style>
