<template>
  <div
    v-for="(row, idx) in rows"
    :key="idx"
    class="list-group-item"
    style="height: 63px"
  >
    <div class="row">
      <div
        :class="topAccsPage ? 'col-xxl-4' : 'col-xxl-6'"
        class="d-none d-xxl-flex"
      >
        <div
          class="flex-shrink-0 rounded-3 d-flex align-self-center"
          style="height: 36px; width: 36px"
        ></div>
        <div class="flex-shrink-0 ms-3">
          <p class="small mb-0 font-monospace"></p>
        </div>
      </div>
      <div class="col-xxl-2 d-none d-xxl-block"></div>
      <div class="col-xxl-2 d-none d-xxl-block"></div>
      <div class="col-xxl-2 d-none d-xxl-block"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountSummarySkeleton",
  components: {},
  props: { topAccsPage: Boolean },
  setup() {
    return {
      rows: parseInt(process.env.VUE_APP_API_LIMIT),
    };
  },
});
</script>
